import NextImage, {
  ImageLoader,
  ImageProps as NextImageProps,
} from 'next/image'
import { useMemo, useState } from 'react'
import { SetOptional } from 'type-fest'

import { getPlaceholderColor } from './AnimatedColor'
import { useImageLoader } from './loaders'

export type LandfolkImageProps = {
  /**
   * The GraphQL typename of the image.
   */
  __typename?: string

  /**
   * The URL of the image.
   */
  url: NextImageProps['src']

  /**
   * The proxy to use for the image.
   */
  proxy?: 'IMGIX' | 'LOCAL'

  /**
   * The blurhash string for generating the placeholder color.
   * If false, no placeholder will be used.
   */
  blurhash?: string | false

  /**
   * A custom image loader function.
   */
  loader?: ImageLoader

  /**
   * The animation style when the image loads.
   * @default 'none'
   */
  animate?: 'fade' | 'none'

  /**
   * The x-coordinate of the focal point for cropping (0-1).
   */
  focalPointX?: number

  /**
   * The y-coordinate of the focal point for cropping (0-1).
   */
  focalPointY?: number

  /**
   * The image resize fit mode (for imgix only).
   *
   * https://docs.imgix.com/apis/rendering/size/resize-fit-mode
   */
  fit?:
    | 'clamp'
    | 'clip'
    | 'crop'
    | 'facearea'
    | 'fill'
    | 'fillmax'
    | 'max'
    | 'min'
    | 'scale'

  /**
   * Prevents Pinterest browser extensions from adding "Pin this" UI to the image.
   *
   * https://help.pinterest.com/en/business/article/prevent-saves-to-pinterest-from-your-site
   */
  nopin?: boolean
}

export type ImageProps = LandfolkImageProps & Omit<NextImageProps, 'src'>

// We're not really using blur placeholder, but background color. We still need something to
// pass next/image `blurDataURL` to kick it into the right state. So we placehold with nothing.
const EMPTY_PLACEHOLDER =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

/**
 * @description
 * The Image component is built on top of Next.js' `next/image` component and provides additional features:
 *
 * - Imgix image loader: If the `proxy` prop is set to `'IMGIX'`, the `imgixLoader` custom loader is used.
 * - Custom blurhash placeholder: Instead of using a blurred image placeholder, a single fill color is calculated from the blurhash and used as the CSS background color, similar to Instagram.
 * - Animation: A small fade-in animation is applied when the image loads, if the `animate` prop is set to `'fade'`.
 * - Focal point: If `focalPointX` and `focalPointY` are defined, they are sent to the imgix loader to be used in cropping URL parameters.
 * - No Pin: Setting `nopin` prevents Pinterest browser extensions from adding "Pin this" UI to the image.
 *
 * @example
 * ```tsx
 * <Image
 *   url="https://example.com/image.jpg"
 *   width={500}
 *   height={300}
 *   blurhash="LGF5]+Yk^6#M@-5c,1J5@[or[Q6."
 *   animate="fade"
 *   focalPointX={0.5}
 *   focalPointY={0.3}
 *   nopin
 * />
 * ```
 */
export default function Image(props: ImageProps) {
  const {
    __typename: _ /* eslint-disable-line @typescript-eslint/no-unused-vars */,
    url,
    proxy,
    blurhash,
    animate = 'none',
    focalPointX,
    focalPointY,
    nopin,
    alt,
    width,
    height,
    fit,
    quality,
    unoptimized,
    onLoad,
    className,
    style,
    ...rest
  } = props

  const isSvg = Boolean(typeof url === 'string' && url.includes('.svg'))

  const imageBaseProps = useImageBaseProps({
    url,
    proxy,
    focalPointX,
    focalPointY,
    alt,
    width,
    height,
    fit,
    quality,
    unoptimized: unoptimized ?? isSvg,
  })

  const shouldUsePlaceholder = Boolean(blurhash && animate === 'none')
  const [loaded, setLoaded] = useState(false)

  const backgroundColor = useMemo(
    () => getPlaceholderColor(blurhash),
    [blurhash],
  )

  return (
    <NextImage
      {...imageBaseProps}
      placeholder={shouldUsePlaceholder ? 'blur' : undefined}
      blurDataURL={shouldUsePlaceholder ? EMPTY_PLACEHOLDER : undefined}
      className={className}
      tx={[
        !rest.priority &&
          animate === 'fade' &&
          tx`transition-opacity duration-1000`,
        !rest.priority &&
          animate === 'fade' &&
          (loaded ? tx`opacity-100` : tx`opacity-0`),
      ]}
      style={{
        backgroundColor,
        ...style,
      }}
      onLoad={(img) => {
        if (animate !== 'none') {
          setLoaded(true)
        }
        onLoad?.(img)
      }}
      // Disable pinterest extension from adding its UI to image
      {...(nopin ? { nopin: 'nopin' } : {})}
      {...(rest.priority ? { fetchpriority: 'high' } : {})}
      {...rest}
    />
  )
}

export function useImageBaseProps({
  url,
  proxy,
  focalPointX,
  focalPointY,
  alt,
  width,
  height,
  fit,
  quality,
  unoptimized,
}: Pick<
  SetOptional<ImageProps, 'alt'>,
  | 'url'
  | 'proxy'
  | 'focalPointX'
  | 'focalPointY'
  | 'alt'
  | 'width'
  | 'height'
  | 'fit'
  | 'quality'
  | 'unoptimized'
>) {
  const { src, loader } = useImageLoader({
    url,
    proxy,
    width,
    height,
    fit,
    focalPointX,
    focalPointY,
  })

  return {
    src,
    loader,
    alt: alt ?? '',
    width,
    height,
    quality: quality ? Number(quality) : undefined,
    unoptimized,
  } satisfies NextImageProps
}
