import { TailwindClasses, TxComponent } from '@landfolk/styling/types/landstyle'
import { getBlurHashAverageColor } from 'fast-blurhash'
import { ReactNode, useMemo } from 'react'

import Image, { ImageProps } from '.'

/**
 *
 * Wrap image in div with average background color from image
 * and fade image in when loaded.
 *
 * Regular `Image` can't animate fade between placeholder and image,
 * so use this component for that.
 */
export default function ImageAnimatedColor({
  blurhash,
  alt,
  wrapperStyle,
  animate = 'fade',
  className,
  ...restProps
}: ImageProps & { wrapperStyle?: TailwindClasses }) {
  return (
    <ImageAverageColorWrapper blurhash={blurhash} tx={[wrapperStyle]}>
      <Image
        alt={alt}
        blurhash={blurhash}
        animate={animate}
        className={className}
        {...restProps}
      />
    </ImageAverageColorWrapper>
  )
}

export const ImageAverageColorWrapper = ({
  blurhash,
  style,
  ...props
}: {
  blurhash?: string | false
  children: ReactNode
} & TxComponent) => {
  const backgroundColor = useMemo(
    () => getPlaceholderColor(blurhash),
    [blurhash],
  )

  return <div style={{ backgroundColor, ...style }} {...props} />
}

export const getPlaceholderColor = (blurhash?: string | false) =>
  blurhash ? `rgb(${getBlurHashAverageColor(blurhash).join(',')})` : '#f7f2e9)' // beach
